// ==============================|| Handle Response REDUCER ||============================== //

const initialState = {
    total: 0,
    currentPage: 1,
    recordPerPage: 10,
    searchStr: '',
    order: 'DESC',
    orderBy: 'id',
    listingData: [],
    startDate: '',
    endDate: '',
    roleId: '',
    materialId: '',
    assetId: '',
    siteId: '',
    itemCategoryId: '',
    outOfStock: false,
    currentData: undefined
};

const listingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LISTING': {
            return {
                ...state,
                listingData: action.payload?.listingData,
                currentpage: action.payload?.currentpage,
                total: Number(action.payload?.total)
            };
        }

        case 'UPDATE_LISTING': {
            return {
                ...state,
                listingData: action.payload?.listingData
            };
        }

        case 'CHANGE_RECORD_PER_PAGE': {
            return {
                ...state,
                recordPerPage: Number(action.payload?.recordPerPage)
            };
        }

        case 'SET_ORDER': {
            return {
                ...state,
                order: action.payload.order,
                orderBy: action.payload?.orderBy
            };
        }

        case 'SET_CURRENT_PAGE': {
            return {
                ...state,
                currentPage: action.payload?.currentPage
            };
        }
        case 'SET_SEARCH_STR': {
            return {
                ...state,
                searchStr: action.payload?.searchStr
            };
        }
        case 'SET_ROLE_ID': {
            return {
                ...state,
                roleId: action.payload?.roleId
            };
        }
        case 'SET_MATERIAL_ID': {
            return {
                ...state,
                materialId: action.payload?.materialId
            };
        }
        case 'SET_ASSET_ID': {
            return {
                ...state,
                assetId: action.payload?.assetId
            };
        }
        case 'SET_SITE_ID': {
            return {
                ...state,
                siteId: action.payload?.siteId
            };
        }
        case 'SET_ITEM_CATEGORY_ID': {
            return {
                ...state,
                itemCategoryId: action.payload?.itemCategoryId
            };
        }
        case 'SET_OUT_OF_STOCK': {
            return {
                ...state,
                outOfStock: action.payload?.outOfStock
            };
        }
        case 'SET_START_DATE': {
            return {
                ...state,
                startDate: action.payload?.startDate
            };
        }
        case 'SET_END_DATE': {
            return {
                ...state,
                endDate: action.payload?.endDate
            };
        }

        case 'SET_CURRENT_DATA': {
            return {
                ...state,
                currentData: action.payload?.currentData
            };
        }
        case 'RESET_LISTING': {
            return {
                ...state,
                listingData: []
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default listingReducer;
